/********** JET SET ***********/

body {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #ececec;
}

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
}

p {
    font-size: 1.25em;
    line-height: 1.6;
    color: #000;
}

hr {
    max-width: 400px;
    border-color: #999999;
}

.horizontal-center {
    text-align: center;
}

/************ BRAND ************/

.brand, .address-bar {
    display: none;
}

.brand {
    margin-top: 0px;
}

.brand img {
    display: inline;
    width: 30%;
}

.brand-before, .brand-name {
    text-transform: capitalize;
}

.brand-before {
    margin: 15px 0;
}

.navbar-default .navbar-brand {
    color: white;
}

.navbar-default .navbar-toggle {
    background: white;
}

/*********** NAVBAR *************/

.navbar {
    background: #D90A00;
    color: white;
}

.navbar-brand {
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2px;
}

.navbar-nav {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 3px;
}

.navbar-default .navbar-nav>li>a {
    text-decoration: none;
    color: white;
}

/******** CONTAINER ********/

.content.container {
    background: white;
    padding-bottom: 20px;
    padding-top: 20px;
}

/******** OTHER ***********/

.img-full {
    min-width: 100%;
}

.tagline-divider {
    margin: 15px auto 3px;
    max-width: 250px;
    border-color: #999999;
}

.box {
    margin-bottom: 20px;
    padding: 30px 15px;
    background: #fff;
    background: rgba(255, 255, 255, 0.9);
}

.intro-text {
    text-transform: uppercase;
    font-size: 1.25em;
    font-weight: 400;
    letter-spacing: 1px;
}

.img-border {
    float: none;
    margin: 0 auto 0;
    border: #999999 solid 1px;
}

.img-left {
    float: none;
    margin: 0 auto 0;
}

footer {
    background: #fff;
    background: rgba(255, 255, 255, 0.9);
    margin-top: 40px;
}

footer p {
    margin: 0;
    padding: 40px 0;
}

.center-text {
    text-align: center;
}

@media screen and (min-width:768px) {
    .container {
        margin-left: 10%;
        margin-right: 10%;
        width: auto;
    }
    .brand {
        display: inherit;
        margin: 0;
        padding: 10px 0 0px;
        text-align: center;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        font-family: "Josefin Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 5em;
        font-weight: 700;
        line-height: normal;
        color: #fff;
    }
    .brand img {
        width: 30%;
    }
    .top-divider {
        margin-top: 0;
    }
    .img-left {
        float: left;
        margin-right: 25px;
    }
    .address-bar {
        display: inherit;
        margin: 0;
        padding: 0 0 40px;
        text-align: center;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
        font-size: 1.25em;
        font-weight: 400;
        letter-spacing: 3px;
        color: #fff;
    }
    .navbar {
        margin-top: 20px;
        border-radius: 0;
    }
    .navbar-default .navbar-nav>li>a:hover {
        background-color: black;
        color: white;
    }
    .navbar-nav>li.float-right {
        float: right;
    }
    .navbar-header {
        display: none;
    }
    .navbar {
        min-height: 0;
    }
    .navbar-default {
        border: none;
    }
    .nav>li>a {
        padding: 20px;
    }
    .navbar-nav>li>a {
        line-height: normal;
    }
    .navbar-nav {
        margin: 0 auto;
        font-size: 125%;
    }
}

@media screen and (min-width:1200px) {
    .box:after {
        content: '';
        display: table;
        clear: both;
    }
    .brand img {
        width: 30%;
    }
}