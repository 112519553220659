/* INPUT */
.checkboxke label {
  font-weight: normal; }

.checkboxke > input[type="checkbox"] {
  margin: 0px;
  margin-right: 10px;
  appearance: none;
  width: 40px;
  height: 16px;
  border: 1px solid #aaa;
  border-radius: 2px;
  background: #ebebeb;
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  transition: background 0.3s;
  box-sizing: border-box; }

.checkboxke > input[type="checkbox"]:after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  width: 14px;
  height: 14px;
  background: white;
  border: 1px solid #aaa;
  border-radius: 2px;
  transition: left 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86); }

.checkboxke > input[type="checkbox"]:checked {
  background: #a6c7ff;
  border-color: #8daee5; }

.checkboxke > input[type="checkbox"]:checked:after {
  left: 23px;
  border-color: #8daee5; }
