
.form-label{
    text-transform: capitalize;
}
.is-invalid {
    border-color: red;
}

.is-invalid, .invalid-feedback{
    color: red;
}